<template>
  <div  class='body'>
    <Nav></Nav>
    <div class="banner" style="margin-bottom:12px">
        <swiper ref="bannerSwiper" :options="bannerSwiperOptions" v-if="pageData.bannerList && pageData.bannerList.length">
          <swiper-slide v-for="img in pageData.bannerList" :key="img.id">
            <base-img class="banner-img" :src="img.url"></base-img>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    <div class='page-body page-content card'>
      <div class='detail' v-if='detail.essaySource'>
        <div class='news-title'>{{detail.essaySource.title}}</div>
        <div class='news-subtitle'>摘要：{{detail.essaySource.subTitle||'暂无摘要'}}</div>
        <div class='news-content' v-html='detail.essaySource.content'></div>
        <div class='news-auth-box'>
          <div class='news-auth'>{{detail.essaySource.author}}</div>
          <div class='news-date'>发布时间：{{detail.essaySource.releaseDate}}</div>
        </div>
      </div>
      <div v-else class='no-data'>
        <a-empty description='未查询到该文章'></a-empty>
        <a-button @click='$router.replace("/news")' type='link' style='margin-top: 24px'>返回列表</a-button>
      </div>
      <div class='files' v-if="detail.docSourceList&&detail.docSourceList.length">
        <div class='title'>附件：</div>
        <div>
          <a v-for='item in detail.docSourceList' :key='item.docSourceId' target="_blank" :href="baseFileUrl + item.docPath" download>{{item.docName}}</a>
        </div>
      </div>
      <div class='flex-a-c flex-j-b' style='padding: 24px 0'>
        <div class='change-new'>
          <div v-if='prev' @click='goPrevNew'>上一篇：{{prev.title}}</div>
        </div>
        <div class='change-new'>
          <div @click='goNextNew' v-if='next'>下一篇：{{next.title}}</div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import { design, system } from '@/api'
import Nav from '@/components/pageComponents/Nav'
import Footer from '@/components/pageComponents/Footer'
export default {
  name: 'NewsDetail',
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      baseFileUrl: process.env.VUE_APP_FiLE,
      id: this.$route.params.id,
      bannerSwiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable :true,
        },
        effect: 'fade',
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        loop: true,
      },
      editStatus: false,
      pageData: {},
      detail: {},
      prev: '',
      next: '',
      loading: true
    }
  },
  watch: {
    $route() {
      this.getDetail()
    },
  },
  async mounted() {
    this.loading = true
    await this.getDesignData()
    await this.getDetail()
    this.loading = false
  },
  methods: {
    async getDetail() {
      const { data, msg, code } = await system.getArticleDetail({
        essaySourceId: this.id,
      })
      if (code === '00000') {
        this.detail = data.now
        this.prev = data.previous
        this.next = data.next
      } else {
        this.$message.error(msg || '获取文章详情失败')
      }
    },
    async getDesignData() {
      const { data, msg, code } = await design.getDesign({ type: 'newDetail' })
      if (code === '00000') {
        if (data) {
          this.designStorageId = data.designStorageId
          this.pageData = JSON.parse(data.designData)
        }
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    goNextNew() {
      this.id = this.next.essaySourceId
      this.$router.replace('/news/detail/' + this.next.essaySourceId)
    },
    goPrevNew() {
      this.id = this.prev.essaySourceId
      this.$router.replace('/news/detail/' + this.prev.essaySourceId)
    }
  },
}
</script>

<style lang="less" scoped>
.card {
  margin: 0 auto 16px;
  padding: 16px 32px;
}
.detail {
  color: #000;
  padding: 32px 0 16px;
  .news-title {
    font-size: 28px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 24px;
  }
  .news-auth-box {
    margin-bottom: 16px;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 160px;
    .news-auth {
      font-size: 16px;
      line-height: 16px;
      color: #333;
    }
    .news-date {
      font-size: 14px;
      color: #999;
      line-height: 12px;
      margin-top: 12px;
    }
  }
  .news-subtitle {
    font-size: 14px;
    color: #666;
    text-align: center;
    margin: 0 auto 16px;
  }
  .news-content {
    font-size: 16px;
    color: #000;
    line-height: 28px;
    ::v-deep p {
      text-indent: 2em;
    }
  }
}
.change-new {
  color: #38b352;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 0;
}
.files {
  font-size: 16px;
  display: flex;
  .title {
    color: #000;
  }
  a {
    display: block;
    cursor: pointer;
    &:hover {
      color: @main-color;
    }
  }
}
</style>